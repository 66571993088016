import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import { makeStyles } from '@material-ui/core/styles'
import {
  DialogContent,
  DialogActions,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  Dialog,
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { getOptions } from '../../utils/ApiConnect'
import { AlertMessage } from '../../contents/AlertMessage'
import { ButtonSubmit, ButtonStandar as ButtonDefault } from '../../contents/Buttons'
import FormLicense from './FormLicense'
import { validateFieldLicenses } from '../../constants/validator'


function DialogTitle(props) {
  const classes = useStyles()
  const { children, onClose, loading, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          disabled={Boolean(false)}
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

export default function DetailLicenses(props) {
  const { items, callback, isAdmin } = props
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const [loading, setLoading] = useState(false)
  const [changes, setChanges] = useState(false)
  const [timestampActiveUntil, setTimestampActiveUntil] = useState(
    items.timestampActiveUntil
  )
  const [back_url, setBack_url] = useState(items.back_url || '')
  const [openAlert, setOpenAlert] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [msg, setMgs] = useState({})
  const [frEmail, setFrEmail] = useState('')
  // const [licenseType, setLicenseType] = useState('')
  const [web, setWeb] = useState('')
  const [userKey, setUserKey] = useState('')
  let msgErrorField = {}
  

  useEffect(() => {
    const back_urlOld = ( !items.back_url ? '' : items.back_url)
    if ( userKey !== '' && (back_url !== back_urlOld || timestampActiveUntil !== items.timestampActiveUntil || frEmail !== items.frEmail) ) {
      setChanges(true)
    } else {
      setChanges(false)
    }
  }, [back_url, timestampActiveUntil, frEmail])

  const handleClose = () => {
    setOpen(false)
    callback(null)
  }

  const handleCloseAlert = () => {
    setOpenAlert(false)
    setMgs({})
  }

  const handleOpenAlert = (message) => {
    setMgs(message)
    setOpenAlert(true)
  }

   
  const handleUpdateLicenses = async (event) => {
    event.preventDefault()
    setSubmit(true)
    setMgs({})
    setOpenAlert(false)

    if (
        validateFieldLicenses({
        back_url,
        timestampActiveUntil,
        }).is_valid
    ) {
        setLoading(true)

        let headers = await getOptions()
        let body = {
        timestampActiveUntil,
        back_url,
        frEmail,
        userKey,
        licenseType: 'Premium',
        web
        }
        await API.put('ApiFormRip', `/licenses`, { body, ...headers })
        .then((response) => {
            console.info(body)
            console.log(response)
            handleOpenAlert({
            message: 'Licencia actualizada con exíto',
            type: 'success',
            })
            setSubmit(false)
            // handleUpdateList()
        })
        .catch((error) => {
            if (!error || !error.response) {
            handleOpenAlert({
                message: 'Falló la conexión del servicio, intente más tarde.',
                type: 'info',
            })
            } else {
            console.log(error.response)
            console.log(error.request)
            const { data } = error.response
            if (data && data.Response) {
                handleOpenAlert({
                message: data.Response,
                type: 'error',
                })
            } else {
                handleOpenAlert({
                message: 'Falló la conexión del servicio, intente más tarde.',
                type: 'info',
                })
            }
            }
        })

        setLoading(false)
    }
  }

  if (submit) {
    msgErrorField = validateFieldLicenses({
      back_url,
    }).msg
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
      >
        <form
          className={classes.form}
          noValidate
          autoComplete="off"
          onSubmit={handleUpdateLicenses}
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleClose}
            loading={true}
          >
            Detalle de la licencia
          </DialogTitle>
          <DialogContent>
            <AlertMessage
              msg={msg}
              openAlert={openAlert}
              handleClose={handleCloseAlert}
            />
            <FormLicense
              items={items}
              setTimestampActiveUntil={setTimestampActiveUntil}
              setBack_url={setBack_url}
              back_url={back_url}
              loading={loading}
              msgErrorField={msgErrorField}
              timestampActiveUntil={timestampActiveUntil}
              frEmail={frEmail}
              setFrEmail={setFrEmail}
              // setLicenseType={setLicenseType}
              setWeb={setWeb}
              setUserKey={setUserKey}
              isAdmin={isAdmin}
            />
          </DialogContent>
          <DialogActions>
            <ButtonDefault
              title="Cancelar"
              color="secondary"
              variant="contained"
              callback={handleClose}
            />
            <ButtonSubmit
              type="submit"
              title="Guardar"
              fullWidth={false}
              disabled={!changes}
              loading={loading}
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    content: {
      paddingTop: '1em',
      fontFamily: 'sans-serif',
      fontSize: '16px',
      lineHeight: '24px',
    },
    title: {
      color: '#000000',
      fontFamily: 'sans-serif',
      fontSize: '20px',
      fontWeight: 'bold',
      lineHeight: '33px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialog: {
      padding: '1em',
    },
    dialogActions: {
      padding: '1em 1em',
    },
    inputPicker: {
      marginBottom: '0px',
      '& input': {
        fontSize: '14px',
      },
      '& > .MuiFormLabel-root': {
        fontSize: '18px',
      },
    },
  }
})
