import React, { useEffect, useState } from 'react'
import ListLicenses from './ListLicenses'
import { withWidth } from '@material-ui/core'
import { isLoggedIn } from '../../utils/auth'
import { navigate } from 'gatsby'
import FormLicense from './FormLicense'
import { Auth } from 'aws-amplify'

function LicensesPage(props) {
  // const [user, setUser] = useState('')
  const [attributes, setAttributes] = useState('')

  useEffect(() => {
    ;(async () => {
      if (await !isLoggedIn()) {
        navigate('/signin/')
      }
      const user = await Auth.currentAuthenticatedUser()
      // const attributes = user.attributes
      setAttributes(user.attributes)
    })()
  }, [])

  return (
    <div>
      {attributes &&
        (attributes['custom:isAdmin'] ? (
          <ListLicenses isAdmin={true} {...props} />
        ) : attributes['custom:userKey'] ? (
          <FormLicense
            isAdmin={false}
            items={{ userKey: attributes['custom:userKey'] }}
            {...props}
          />
        ) : (
          <>No tienes licencia asociada a tu cuenta</>
        ))}
    </div>
  )
}

export default withWidth()(LicensesPage)
