import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import DateFnsUtils from '@date-io/date-fns'
import { makeStyles } from '@material-ui/core/styles'
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { Input } from '../../contents/Input'
import enLocale from 'date-fns/locale/es'
import { getOptions } from '../../utils/ApiConnect'
import { ButtonStandar } from '../../contents/Buttons'


const InitLicenses = {
    frEmail: '',
    back_url: '',
    // licenseType: '',
    timestampCreated: '',
    timestampActiveUntil: '',
    userKey: '',
    web: ''
}


export default function FormLicense(props) {
  const classes = useStyles()
  const { items, 
      setTimestampActiveUntil, 
      setBack_url,
      back_url,
      loading, 
      msgErrorField, 
      timestampActiveUntil,
      setFrEmail,
      frEmail,
      // setLicenseType,
      setWeb,
      setUserKey,
      isAdmin } = props

    const [detail, setDetail] = useState(InitLicenses)
    const [paymentLink, setPaymentLink] = useState('')
 
    const generatePaymentLink = async () => {
      let headers = await getOptions()
      let body = {
        userKey: detail.userKey
      }
      await API.post('ApiFormRip', `/payments`, { body, ...headers })
        .then((response) => {
          console.log(response)
          const { payment_url } = response.Response
          setPaymentLink(payment_url)
          if (!isAdmin) {
            window.location.href = payment_url
          }
        })
        .catch((error) => {
          console.log(error.response)
          console.log(error.request)
          // const { data } = error.response
        })
      }

    useEffect(() => {
        ;(async () => {
          if (items) {
            await API.get(
              'ApiFormRip',
              `/licenses/${items.userKey}`,
              await getOptions()
            )
              .then(response => {
                setDetail(response.Response)
                if (typeof setTimestampActiveUntil === "function") {
                    setTimestampActiveUntil(response.Response.timestampActiveUntil)
                    setBack_url(response.Response.back_url)
                    setFrEmail(response.Response.frEmail)
                    // setLicenseType(response.Response.licenseType)
                    setWeb(response.Response.web)
                    setUserKey(response.Response.userKey)
                }
              })
              .catch(error => {
                console.log(error)
              })
            }
        })()
    }, [items])

    return (
        <div>
            <Input
              variant="standard"
              name="userKey"
              type="text"
              label="ApiKey"
              loading={true}
              value={`${detail.userKey}`}
              // value={items.web}
            />
            <Input
              variant="standard"
              name="web"
              type="text"
              label="Sitio web"
              loading={true}
              value={`${detail.web}`}
              // value={items.web}
            />
            <Input
              variant="standard"
              name="frEmail"
              type="text"
              label="Correo electrónico"
              loading={loading}
              value={frEmail}
              // value={`${items.frEmail}`}
              setCallBack={setFrEmail}
            />
            <Input
              variant="standard"
              name="back_url"
              type="text"
              label="Sitio web de retorno"
              loading={loading}
              value={`${back_url || ''}`}
              // value={back_url}
              msgErrorField={msgErrorField}
              setCallBack={setBack_url}
            />
            {/* <Input
              variant="standard"
              name="licenseType"
              type="text"
              label="Tipo de licencia"
              loading={true}
              value={`${detail.licenseType}`}
              msgErrorField={msgErrorField}
              // value={`${items.licenseType}`}
            /> */}
            <Input
              variant="standard"
              name="timestampCreated"
              type="datetime"
              label="Fecha de emision"
              loading={true}
              value={`${detail.timestampCreated}`}
              // value={`${items.timestampCreated}`}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                value={timestampActiveUntil}
                onChange={setTimestampActiveUntil}
                minDate={new Date()}
                format="yyyy-MM-dd hh:mm"
                inputVariant="standard"
                fullWidth
                margin="normal"
                size="medium"
                label="Fecha vencimiento"
                className={classes.inputPicker}
                locale={enLocale}
                disabled={loading}
              />
            </MuiPickersUtilsProvider>
            { !paymentLink? <ButtonStandar
              title={( isAdmin ? "Generar Link de pago" : "Ir a pagar")}
              color="primary"
              variant="contained"
              callback={generatePaymentLink}
            /> : 
            <Input
              variant="standard"
              name="timestampCreated"
              type="datetime"
              label="Link de pago cliente"
              loading={true}
              value={paymentLink}
              // value={`${items.timestampCreated}`}
            />}
        </div>
    )
}


const useStyles = makeStyles(theme => {
    return {
      root: {
        margin: 0,
        padding: theme.spacing(2),
      },
      content: {
        paddingTop: '1em',
        fontFamily: 'sans-serif',
        fontSize: '16px',
        lineHeight: '24px',
      },
      title: {
        color: '#000000',
        fontFamily: 'sans-serif',
        fontSize: '20px',
        fontWeight: 'bold',
        lineHeight: '33px',
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
      dialog: {
        padding: '1em',
      },
      dialogActions: {
        padding: '1em 1em',
      },
      inputPicker: {
        marginBottom: '0px',
        '& input': {
          fontSize: '14px',
        },
        '& > .MuiFormLabel-root': {
          fontSize: '18px',
        },
      },
    }
  })