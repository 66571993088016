import React from 'react'
import LicensesPage from '../components/LicensesPage'
import Layout from '../components/Layout/Layout'

function Licenses(props) {
  return (
    <Layout {...props}>
      <LicensesPage {...props} />
    </Layout>
  )
}
export default Licenses
