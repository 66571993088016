import React, { useState, useEffect } from 'react'
// import Moment from 'react-moment'
import { handleConnectionApiGet } from '../../utils/ApiConnect'
import { makeStyles } from '@material-ui/core/styles'
import {
  MoreHoriz as MoreHorizIcon,
  AddBox as AddBoxIcon,
} from '@material-ui/icons'
import { TableRow as TableLicenses } from '../../Contents/TableRow'
import DetailLicenses from './DetailLicenses'
import CreateLicenses from './CreateLicenses'

const columns = [
  { title: 'ApiKey', field: 'userKey' },
  { title: 'Email', field: 'frEmail' },
  { title: 'Sitio web', field: 'web' },
  { title: 'Tipo de licencia', field: 'licenseType' },
  {
    title: 'Fecha de emisión',
    field: 'timestampCreated',
    // render: (rowData) => (
    //   <Moment format="DD-MM-YYYY h:mm:ss a">{rowData}</Moment>
    // ),
  },
  {
    title: 'Fecha de vencimiento',
    field: 'timestampActiveUntil',
    // render: (rowData) => (
    //   <Moment format="DD-MM-YYYY h:mm:ss a">{rowData}</Moment>
    // ),
  },
]

export default function ListLicenses(props) {
  const classes = useStyles()
  const [rows, setRows] = useState([])
  const [componentDetail, setComponentDetail] = useState(null)
  const [componentCreate, setComponentCreate] = useState(null)
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null)
  const actions = [
    {
      icon: () => <MoreHorizIcon fontSize="large" />,
      tooltip: '',
      onClick: (event, rowData) => handleOpenDetail(event, rowData),
    },
    {
      icon: () => <AddBoxIcon fontSize="large" />,
      tooltip: 'Agregar licencias',
      isFreeAction: true,
      onClick: () => handleOpenCreate(),
    },
  ]

  useEffect(() => {
    let abortController = new AbortController()

    if (lastEvaluatedKey !== undefined) {
      ;(async () => {
        await handleConnectionApiGet('/licenses', { ...lastEvaluatedKey })
          .then((response) => {
            const { items, LastEvaluatedKey } = response.Response
            setRows((state) => [...state, ...items])
            if (LastEvaluatedKey) {
              setLastEvaluatedKey(LastEvaluatedKey)
            }
            setLastEvaluatedKey(undefined)
          })
          .catch((error) => {
            console.log(error)
            setLastEvaluatedKey(undefined)
          })
      })()
    }
    return function cleanup() {
      abortController.abort()
    }
  }, [lastEvaluatedKey])

  const handleOpenDetail = (event, items) => {
    setComponentDetail(
      <DetailLicenses 
        callback={setComponentDetail}
        items={items}
        {...props} 
      />
    )
  }

  const handleOpenCreate = () => {
    setComponentCreate(
      <CreateLicenses
        callback={setComponentCreate}
        handleUpdateList={handleLastEvaluatedKey}
        {...props}
      />
    )
  }
  const handleLastEvaluatedKey = () => {
    setRows([])
    setLastEvaluatedKey(null)
  }
  return (
    <div className={classes.root}>
      <TableLicenses
        title="Licencias"
        columns={columns}
        data={rows}
        actions={actions}
      />
      {componentDetail}
      {componentCreate}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))
