import React, { useState } from 'react'
import { API } from 'aws-amplify'
import { makeStyles } from '@material-ui/core/styles'
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import enLocale from 'date-fns/locale/es'

import {
  DialogContent,
  DialogActions,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  Dialog,
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { getOptions } from '../../utils/ApiConnect'
import { AlertMessage } from '../../contents/AlertMessage'
import {
  ButtonSubmit,
  ButtonStandar as ButtonCancelar,
} from '../../contents/Buttons'
import { Input } from '../../contents/Input'
// import { Select } from '../../contents/Select'
// import { Licenses } from '../../constants/typeLicenses'
import { validateFieldLicenses } from '../../constants/validator'

function DialogTitle(props) {
  const classes = useStyles()
  const { children, onClose, loading, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          disabled={Boolean(false)}
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

export default function CreateLicenses(props) {
  const classes = useStyles()
  const { callback, handleUpdateList } = props
  const [open, setOpen] = useState(true)
  const [frEmail, setFrEmail] = useState('')
  const [web, setWeb] = useState('')
  // const [licenseType, setLicenseType] = useState('')
  const [back_url, setBack_url] = useState('')
  const [timestampActiveUntil, setTimestampActiveUntil] = useState(null)
  const [loading, setLoading] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [msg, setMgs] = useState({})
  let msgErrorField = {}

  const handleCreateLicenses = async (event) => {
    event.preventDefault()
    setSubmit(true)
    setMgs({})
    setOpenAlert(false)
    if (
      validateFieldLicenses({
        frEmail,
        web,
        // licenseType,
        timestampActiveUntil,
        back_url
      }).is_valid
    ) {
      setLoading(true)

      let headers = await getOptions()
      let body = {
        frEmail,
        web,
        licenseType: 'Premium',
        timestampActiveUntil,
        back_url,
      }
      await API.post('ApiFormRip', `/licenses`, { body, ...headers })
        .then((response) => {
          handleOpenAlert({
            message: 'Licencia generada con exíto',
            type: 'success',
          })
          setFrEmail('')
          setWeb('')
          // setLicenseType('')
          setBack_url('')
          setTimestampActiveUntil(null)
          setSubmit(false)
          handleUpdateList()
        })
        .catch((error) => {
          console.log(error.response)
          console.log(error.request)
          const { data } = error.response
          if (data && data.Response) {
            handleOpenAlert({
              message: data.Response,
              type: 'error',
            })
          } else {
            handleOpenAlert({
              message: 'Falló la conexión del servicio, intente más tarde.',
              type: 'info',
            })
          }
        })

      setLoading(false)
    }
  }
  const handleClose = () => {
    setOpen(false)
    callback(null)
  }

  const handleCloseAlert = () => {
    setOpenAlert(false)
    setMgs({})
  }

  const handleOpenAlert = (message) => {
    setMgs(message)
    setOpenAlert(true)
  }

  if (submit) {
    msgErrorField = validateFieldLicenses({
      frEmail,
      web,
      // licenseType,
      timestampActiveUntil,
      back_url,
    }).msg
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          loading={true}
        >
          Nueva licencia
        </DialogTitle>
        <form
          className={classes.form}
          noValidate
          autoComplete="off"
          onSubmit={handleCreateLicenses}
        >
          <DialogContent>
            <AlertMessage
              msg={msg}
              openAlert={openAlert}
              handleClose={handleCloseAlert}
            />
            <Input
              name="frEmail"
              type="text"
              placeholder="E-mail"
              value={frEmail}
              loading={loading}
              msgErrorField={msgErrorField}
              setCallBack={setFrEmail}
            />
            <Input
              name="web"
              type="text"
              placeholder="Sitio web"
              value={web}
              loading={loading}
              msgErrorField={msgErrorField}
              setCallBack={setWeb}
            />
            {/* <Select
              name="licenseType"
              currencies={Licenses}
              value={licenseType}
              loading={loading}
              msgErrorField={msgErrorField}
              setCallBack={setLicenseType}
            /> */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                value={timestampActiveUntil}
                onChange={setTimestampActiveUntil}
                minDate={new Date()}
                format="yyyy-MM-dd hh:mm"
                inputVariant="outlined"
                fullWidth
                margin="normal"
                size="small"
                placeholder="Fecha vencimiento"
                mask
                className={classes.inputPicker}
                locale={enLocale}
              />
            </MuiPickersUtilsProvider>
            <Input
              name="back_url"
              type="text"
              placeholder="Sitio de referencia"
              value={back_url}
              loading={loading}
              msgErrorField={msgErrorField}
              setCallBack={setBack_url}
            />
          </DialogContent>
          <DialogActions>
            <ButtonCancelar
              title="Cancelar"
              loading={loading}
              color="#3f51b5"
              callback={handleClose}
            />
            <ButtonSubmit
              type="submit"
              title="Guardar"
              fullWidth={false}
              loading={loading}
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    content: {
      paddingTop: '1em',
      fontFamily: 'sans-serif',
      fontSize: '16px',
      lineHeight: '24px',
    },
    title: {
      color: '#000000',
      fontFamily: 'sans-serif',
      fontSize: '20px',
      fontWeight: 'bold',
      lineHeight: '33px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialog: {
      padding: '1em',
    },
    dialogActions: {
      padding: '1em 1em',
    },
    inputPicker: {
      marginBottom: '0px',
      '& input': {
        border: '#000',
        color: '#000000',
        fontSize: '14px',
        '&::placeholder': {
          fontWeight: 300,
          fontFamily: 'sans-serif',
          opacity: 1,
        },
      },
      '& > .MuiFormLabel-root': {
        fontSize: '1.3em',
      },
    },
  }
})
